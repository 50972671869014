import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { callToActionStyle } from "../styles"

const BlockContainer = styled.div`
  margin: 3rem 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  .fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
`

const Block = styled.div`
  margin: 2.5rem 0;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: flex-start;
  justify-items: end;
  grid-template-rows: auto;
  color: ${props => props.theme.colors.primary};
  h4 {
    font: 400 ${props => props.theme.fontSize.subheader}
      ${props => props.theme.fonts.serif};
    padding-right: 3rem;
  }

  p {
    font-size: ${props => props.theme.fontSize.paragraph};
    line-height: 32px;
    padding-right: 3rem;
    top: -8px;
    position: relative;
  }


  @media only screen and (max-width: 720px) {
    img {
      margin: 2rem 0;
    }
    margin-top: 0;
    display: block;
    text-align: center;
    p {
      padding: 0;
      margin-bottom: 2rem;
    }
    h4 {
      padding: 0;
    }
  }
`
function FadeInSection(props) {
  const [isVisible, setVisible] = React.useState(true)
  const domRef = React.useRef()
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setVisible(entry.isIntersecting)
        if (entry.isIntersecting) {
          observer.unobserve(domRef.current)
        }
      })
    })
    observer.observe(domRef.current)
    return () => observer.unobserve(domRef.current)
  }, [])
  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  )
}

const renderBlock = block => {
  return (
    <FadeInSection>
      <Block>
        <div>
          {block.title && <h4>{block.title}</h4>}
          {block.text && <p>{block.text}</p>}
          {block.cta && (
            <Link css={theme => callToActionStyle(theme)} to={block.cta.href}>
              {block.cta.button}
            </Link>
          )}
        </div>
        {block.img && (
          <Img
            style={{ width: "100%", maxHeight: "400px" }}
            fluid={block.img}
          />
        )}
      </Block>
    </FadeInSection>
  )
}

const HalfBlocks = ({ blocks }) => (
  <BlockContainer>{blocks.map(b => renderBlock(b))}</BlockContainer>
)

export default HalfBlocks
