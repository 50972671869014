import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Splash from "../../components/splash"
import HalfBlocks from "../../components/halfBlocks"

const AboutPage = ({ splashImage, images }) => (
  <Layout>
    <SEO title="About Us" />
    <Splash title="About Us" subtitle="Essex Capital Partners">
      <Img style={{ minHeight: "150px" }}  fluid={splashImage} />
    </Splash>
    <HalfBlocks blocks={renderBlocks(images)} />
  </Layout>
)

const blocks = [
  {
    title: "Essex Capital Partners",
    text:
      "Essex Capital Partners Ltd. is a multi-faceted real estate investment and development company focusing on a broad range of opportunistic projects ranging from ground-up development to the repositioning of existing assets. The company’s vertically integrated platform enables it to successfully identify, acquire, master plan, develop, reposition, and manage a range of asset categories, including residential, office, retail, industrial and undeveloped land.",
    cta: {
      href: "/about/essex-capital-partners",
      button: "Learn more",
    },
  },
  {
    title: "Mitchell B. Rutter",
    text:
      "Mitchell B. Rutter is founding partner and chief executive officer of Essex Capital Partners Ltd., a New York-based real estate investment and development company with office, residential, industrial and retail projects along the East Coast. Rutter established Essex Capital in 1991, and under his leadership the company has acquired, repositioned and developed more than five million square feet of real estate with a value of more than $3 billion.",
    cta: {
      href: "/about/mitchell-b-rutter",
      button: "Learn more",
    },
  },
  {
    title: "Select Projects",
    text:
      "Essex Capital is a diversified portfolio of opportunistic real estate transactions in early-stage, distressed, and value-added development projects along the east coast.",
    cta: {
      href: "/about/select-projects",
      button: "Learn more",
    },
  },
]

const renderBlocks = images => {
  return blocks.map((b, i) => ({
    ...b,
    img: images[i],
  }))
}
export default props => {
  const data = useStaticQuery(graphql`
    query {
      aboutImage: file(relativePath: { eq: "500/cube_400.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rutterImage: file(relativePath: { eq: "about/rutter_400.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      selectImage: file(relativePath: { eq: "more/amsterdam_400.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      splashImage: file(relativePath: { eq: "about/banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const aboutImage = data.aboutImage.childImageSharp.fluid
  const rutterImage = data.rutterImage.childImageSharp.fluid
  const splashImage = data.splashImage.childImageSharp.fluid
  const selectImage = data.selectImage.childImageSharp.fluid
  const images = [aboutImage, rutterImage, selectImage]
  return <AboutPage {...props} splashImage={splashImage} images={images} />
}
